<template>
	<div class="page-container">
		<Head />
		<div class="page-banner" :style="{backgroundImage:'url('+banner+')'}">
			<div class="container banner-align-left">
				<div class="banner-text wow fadeInDown">
					<div class="ch">关于我们</div>
					<div class="en">ABOUT US</div>
					<img src="../assets/image/down-arrow.png" alt="" />
				</div>
			</div>
		</div>
		<div class="about-first">
			<div class="container">
				<div class="about-card wow fadeInDown">
					<div class="about-photo">
						<img src="../assets/image/about-pic.png" alt="" />
					</div>
					<div class="flex_bd">
						<h2>企业介绍</h2>
						<p>云闪汇数字目前的产品及解决方案主要为进出口贸易客户提供一站式解决方案：自贸区园区企业注册登记、报关出口、出口退税、核定征收。合作国内外一流的银行为资金提供足够的安全保障。公司拥有10年以上的跨境行业专家团队，具有非常丰富的行业经验和创新能力。致力于构建阳光化全球资金跨境及国际贸易新生态。</p>
					</div>
				</div>
			</div>
		</div>
		<div class="about-section">
			<div class="section-title wow fadeInDown">合作伙伴</div>
			<div class="container wow fadeInDown">
				<el-row :gutter="20">
					<el-col :xs="12" :span="6">
						<div class="partner-card hover-up">
							<img src="../../src/assets/image/wxpay.png" alt="" />
							<div class="p-label">微信支付</div>
						</div>
					</el-col>
					<el-col :xs="12" :span="6">
						<div class="partner-card hover-up">
							<img src="../../src/assets/image/alipay.png" alt="" />
							<div class="p-label">支付宝</div>
						</div>
					</el-col>
					<el-col :xs="12" :span="6">
						<div class="partner-card hover-up">
							<img src="../../src/assets/image/yunpay.png" alt="" />
							<div class="p-label">云闪付</div>
						</div>
					</el-col>
					<el-col :xs="12" :span="6">
						<div class="partner-card hover-up">
							<img src="../../src/assets/image/xinpay.png" alt="" />
							<div class="p-label">中信银行</div>
						</div>
					</el-col>
				</el-row>
			</div>
		</div>
		<div class="about-section">
			<div class="section-title wow fadeInDown">联系我们</div>
			<div class="container wow fadeInDown">
				<el-row :gutter="20">
					<el-col :xs="12" :span="6">
						<div class="mp-card">
							<span class="iconfont icon-dianhua"></span>
							<div class="mp-label">400 639 9909</div>
						</div>
					</el-col>
					<el-col :xs="12" :span="6">
						<div class="mp-card">
							<span class="iconfont icon-youxiang"></span>
							<div class="mp-label">vipcustom@ystransfer.com</div>
						</div>
					</el-col>
					<el-col :xs="24" :span="12">
						<div class="mp-card">
							<span class="iconfont icon-dizhi"></span>
							<div class="mp-label">深圳市福田区深南大道6021号喜年中心大厦A座1409室</div>
						</div>
					</el-col>
				</el-row>
				<div class="map-panel wow fadeInDown">
					<baidu-map class="bm-view" :center="center" :zoom="zoom" :scroll-wheel-zoom="true">
					</baidu-map>
					<!-- <baidu-map class="map" :center="markerPoint" :zoom="16">
						<bm-marker :position="markerPoint" :dragging="true" @click="infoWindowOpen">
							<bm-info-window :show="show" @close="infoWindowClose" @open="infoWindowOpen"
								style="font-size:13px">
							</bm-info-window>
						</bm-marker>
					</baidu-map> -->
				</div>
			</div>
		</div>
		<Foot />
	</div>
</template>

<script>
	// @ is an alias to /src
	import Head from '@/components/Head.vue'
	import Foot from '@/components/Foot.vue'
	export default {
		name: 'Home',
		components: {
			Head,
			Foot,
		},
		data() {
			return {
				markerPoint: { 
					lng:  114.035087,
					lat: 22.539084
				},
				center: {
					lng:  114.035087,
					lat: 22.539084
				}, // 地图中心点
				zoom: 15, // 缩放级别
				show: true,
				banner: require('../assets/image/banner-about.jpg')
			};
		},
		methods: {
			infoWindowClose() {
				this.show = false
			},
			infoWindowOpen() {
				this.show = true
			}
		},
		mounted() {
			new this.$wow.WOW().init();
		}
	}
</script>
<style scoped>
	.page-container{
		background-color: #f5f7fd;
	}
</style>